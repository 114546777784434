'use client';

import { ReactElement } from 'react';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import {
  Flex,
  Box,
  Text,
  Spacer,
  Link,
  Image,
  useToast
} from '@chakra-ui/react';
import {
  FaGithub,
  FaDiscord,
  FaXTwitter,
  FaTelegram,
  FaGlobe
} from 'react-icons/fa6';
import { Button } from '@app/components/Button';

import { IconBadge } from './IconBadge';
import { useProjectDetails } from '@app/api/useProjectDetails';

import mode from '@app/assets/images/logo/mode.png';
import Alarm from './assets/alarm.svg';
import IonicLogo from '@app/assets/images/logo/ionic.svg';
import IonicText from '@app/assets/images/logo/ionicText.svg';
import KimLogo from '@app/assets/images/logo/kim.svg';
import Kim1 from '@app/assets/images/bg/kim1.svg';
import Kim2 from '@app/assets/images/bg/kim2.svg';
import Kim3 from '@app/assets/images/bg/kim3.svg';
import Kim4 from '@app/assets/images/bg/kim4.svg';
import IroncladLogo from '@app/assets/images/logo/ironclad1.svg';
import IroncladBg from '@app/assets/images/bg/ironclad.svg';
import type { Project } from '@app/pages/api/listProjects';

import type { ProjectId } from '@app/types';

type Props = {
  projectId: ProjectId;
  openEmailSignup: () => void;
  project: Project | null;
  enabled: boolean;
};

const ASSETS: Record<ProjectId, { bg: ReactElement; logo: ReactElement }> = {
  IONIC: {
    bg: (
      <Flex h="100%" justify="center" pt={4} pb={6} px={1.5}>
        <Flex
          h="100%"
          w="100%"
          overflow="hidden"
          justify="center"
          align="center"
          position="relative"
          px="20%"
        >
          <IonicText />
        </Flex>
      </Flex>
    ),
    logo: <IonicLogo />
  },
  KIM: {
    bg: (
      <Flex w="100%" h="100%" overflow="hidden">
        <Flex h="100%" justify="center" direction="column" position="relative">
          <Flex>
            <Kim1 />
            <Kim2 />
          </Flex>
          <Flex>
            <Kim4 />
            <Kim3 />
          </Flex>
        </Flex>
        <Flex h="100%" justify="center" direction="column">
          <Flex w="100%" h="100%">
            <Kim1 />
            <Kim2 />
          </Flex>
          <Flex w="100%" h="100%">
            <Kim3 />
            <Kim4 />
          </Flex>
        </Flex>
      </Flex>
    ),
    logo: (
      <Box w="100%" h="100%" bg="#FF4500" p="9px" borderRadius="50%">
        <KimLogo />
      </Box>
    )
  },
  IRONCLAD: {
    bg: (
      <Flex h="100%" justify="center" pt={4} pb={6} px={1.5}>
        <Flex
          h="100%"
          justify="center"
          align="center"
          position="relative"
          px="20%"
        >
          <IroncladBg />
        </Flex>
      </Flex>
    ),
    logo: (
      <Box w="100%" h="100%" bg="#000" p="9px" borderRadius="50%">
        <IroncladLogo />
      </Box>
    )
  }
};

export const ProjectCard = ({ projectId, openEmailSignup, project }: Props) => {
  const {
    id,
    name,
    description,
    enabled,
    bgColor,
    ticker,
    saleDetails: { startsAt, maxRaiseAmount, ticketSize, endsAt },
    socialURL: { website, twitter, discord, github, telegram }
  } = useProjectDetails(projectId);
  const router = useRouter();
  const toast = useToast();
  //const { maxRaiseAmount } = useSaleDetails({
  //saleContractAddress
  //});
  //const maxRaiseAmount = 300_000;
  //const ticketSize = '$500';

  const saleEnded = endsAt && endsAt < Math.floor(new Date().getTime() / 1000);

  return (
    <Flex
      w="100%"
      h="100%"
      p={4}
      borderRadius={16}
      bg="rgba(255,255,255,0.9)"
      textColor="black"
      gap={8}
      hidden={project?.enable === true ? false : true}
      cursor={enabled ? 'pointer' : 'default'}
      direction={{
        base: 'column',
        md: 'row'
      }}
      onClick={() => {
        if (enabled) {
          router.push(`/projects/${projectId.toLowerCase()}`);
        }
      }}
    >
      <Box
        w="100%"
        h={{ base: 'auto', md: '400px' }}
        aspectRatio={{
          base: 1.52185792,
          md: 0
        }}
        borderRadius={16}
        overflow="hidden"
        position="relative"
      >
        <Box bg={bgColor} w="100%" h="full" flex={1} position="absolute">
          {ASSETS[id].bg}
        </Box>
        <Box position="absolute" bottom={4} left={4}>
          <IconBadge
            text="MODE"
            icon={
              <Image
                src={mode.src}
                alt="icon"
                w="24px"
                h="24px"
                mixBlendMode={'exclusion'}
                style={{
                  WebkitFilter: 'grayscale(100%)',
                  filter: 'grayscale(100%)'
                }}
              />
            }
          />
        </Box>
        <Box position="absolute" top={4} right={4}>
          <IconBadge
            text={
              startsAt
                ? saleEnded
                  ? 'SALE CONCLUDED'
                  : format(new Date(startsAt * 1000), 'M/d/y p O')
                : 'COMING SOON'
            }
            icon={<Alarm />}
            variant="DARK"
          />
        </Box>
      </Box>
      <Flex
        w="100%"
        direction="column"
        py={{ base: 3, md: 6 }}
        align="start"
        h={{ base: 'auto', md: '100%' }}
      >
        <Flex align="center" gap={3}>
          <Box w={10} h={10} borderRadius={'50%'}>
            {ASSETS[id].logo}
          </Box>
          <Text fontSize="3xl" fontWeight="bold">
            {name}
          </Text>
        </Flex>
        <Text opacity={0.5} fontSize="16px" mt={3} textAlign="left">
          {description}
        </Text>
        <Flex
          mt={13}
          w="100%"
          direction="column"
          align="center"
          justify="center"
          h="100%"
          fontSize="lg"
          fontWeight="semibold"
          pr={{
            base: 0,
            md: 8
          }}
          gap={3}
        >
          <Flex w="100%" dir="row" align="center" justify="space-between">
            <Text>Token name</Text>
            <Text>${ticker}</Text>
          </Flex>
          <Box w="100%" h="1px" bg="rgba(0,0,0,0.2)" />
          <Flex w="100%" dir="row" align="center" justify="space-between">
            <Text>Fundraise Goal</Text>
            <Text>{maxRaiseAmount != null ? `$${maxRaiseAmount}` : 'TBA'}</Text>
          </Flex>
          <Box w="100%" h="1px" bg="rgba(0,0,0,0.2)" />
          <Flex w="100%" dir="row" align="center" justify="space-between">
            <Text>Ticket Size</Text>
            <Text>
              {projectId === 'IRONCLAD' // Remove IF
                ? '$500 - $1000'
                : ticketSize != null
                  ? `$${ticketSize}`
                  : 'TBA'}
            </Text>
          </Flex>
          {project?.projectStatus == 'express-interest' && (
            <Button
              text="Express Interest"
              onClick={() => {
                openEmailSignup();
              }}
            />
          )}
        </Flex>
        <Spacer />
        <Flex
          dir="row"
          align="center"
          justify="start"
          gap={4}
          justifySelf="end"
          mt={{ base: 6, md: 0 }}
        >
          {website && (
            <Link href={website} isExternal>
              <FaGlobe size={24} />
            </Link>
          )}
          {twitter && (
            <Link href={twitter} isExternal>
              <FaXTwitter size={24} />
            </Link>
          )}
          {discord && (
            <Link href={discord} isExternal>
              <FaDiscord size={24} />
            </Link>
          )}
          {github && (
            <Link href={github} isExternal>
              <FaGithub size={24} />
            </Link>
          )}
          {telegram && (
            <Link href={telegram} isExternal>
              <FaTelegram size={24} />
            </Link>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
