import { Flex, Text, Box, Image } from '@chakra-ui/react';
import { maxWidths } from '@app/theme';
import { type ReactElement } from 'react';

import baseLogo from './baseLogo.png';
import ModeM from '@app/assets/images/logo/modeM.svg';
import zoraLogo from './zoraLogo.png';
import OptimismLogo from './optimism.svg';

type Multichain = {
  icon: ReactElement;
  bg: string;
  fontColor: string;
  name: string;
};

const MultichainCard = ({ icon, bg, name, fontColor }: Multichain) => (
  <Flex
    bg={bg}
    borderRadius="3xl"
    flexDir="column"
    align="center"
    w={{
      base: '100%',
      md: '240px'
    }}
    py={8}
  >
    {icon}
    <Text
      fontSize="24px"
      fontWeight="bold"
      color={fontColor}
      fontStyle="italic"
      mt={6}
    >
      {name}
    </Text>
  </Flex>
);

const items: Multichain[] = [
  {
    icon: (
      <Box w="54px" h="54px" borderRadius="50%" bg="white">
        <OptimismLogo />
      </Box>
    ),
    bg: 'brandRed',
    name: 'OPTIMISM',
    fontColor: 'white'
  },
  {
    icon: (
      <Box w="54px" h="54px" bg="black" borderRadius="50%">
        <ModeM />
      </Box>
    ),
    bg: '#DFFE00',
    name: 'MODE',
    fontColor: 'black'
  },
  {
    icon: <Image src={zoraLogo.src} alt="mode" h="54px" w="54px" />,
    bg: '#000',
    name: 'ZORA',
    fontColor: 'white'
  },
  {
    icon: <Image src={baseLogo.src} alt="mode" h="54px" w="54px" />,
    bg: '#005BF0',

    name: 'BASE',
    fontColor: 'white'
  }
];

export const MultichainInformation = () => {
  return (
    <Flex
      w="100%"
      flexDir="column"
      align="center"
      pb={{ base: '100px', md: '220px' }}
      zIndex={1}
    >
      <Flex w="100%" maxWidth={maxWidths} flexDir="column" align="center">
        <Flex
          flexDirection="column"
          align="center"
          fontSize={{
            base: '4xl',
            md: '7xl'
          }}
          fontStyle="italic"
          fontWeight="extrabold"
          textAlign="center"
          mt={{ base: '100px', md: '220px' }}
        >
          <Text color="brandRed" lineHeight={'none'}>
            MULTICHAIN
          </Text>
          <Text lineHeight={'none'}>IS THE FUTURE</Text>
          <Text
            textAlign="center"
            fontSize={['sm', 'xl']}
            opacity={0.5}
            fontWeight="bold"
            fontStyle="normal"
            mt={6}
          >
            S1 is a cross-chain community funding platform for the top projects
            launching tokens on Optimism, Base, Mode and Zora
          </Text>
        </Flex>
        <Flex
          w="100%"
          align="center"
          justify="center"
          gap={6}
          mt={20}
          direction={{
            base: 'column',
            md: 'row'
          }}
          px={4}
        >
          {items.map(item => (
            <MultichainCard key={item.name} {...item} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};
