import { type ReactElement } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';

type Props = {
  icon: ReactElement;
  text: string;
  variant?: 'LIGHT' | 'DARK';
};

export const IconBadge = ({ icon, text, variant = 'LIGHT' }: Props) => {
  return (
    <Flex
      align="center"
      borderRadius="md"
      bg={variant === 'LIGHT' ? 'white' : '#151F29'}
      pr={3}
      pl={2}
      py={1.5}
      gap={1.5}
      letterSpacing="1px"
      textColor={variant === 'LIGHT' ? 'black' : '#B2DDFF'}
    >
      <Box h="24px" w="24px">
        {icon}
      </Box>
      <Text
        fontSize={variant === 'LIGHT' ? '16px' : '14px'}
        fontWeight="semibold"
        mt={0.5}
      >
        {text}
      </Text>
    </Flex>
  );
};
