import React from 'react';

import MainLayout from '@app/components/MainLayout';

import { APP_NAME } from '@app/constants/common';
import LandingPage from '@app/screens/landing';

export const Landing = () => {
  return (
    <MainLayout metadata={{ title: `Launchpad | ${APP_NAME}` }}>
      <LandingPage />
    </MainLayout>
  );
};

export default Landing;
