export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
export const USER_LOCATION_WARNING = 'USER_USER_LOCATION_WARNING';

export const APP_NAME = 'Superchain One';

export enum ActionStatus {
  ExpressInterest = 'Express interest',
  InterestAlreadyExpressed = 'Interest already expressed',
  ProceedToKYC = 'Proceed To KYC',
  KYCCompleted = 'KYC completed',
  KYCDenied = 'KYC Rejected',
  NotChosenForKYC = 'Not chosen for KYC',
  KYCNotApplicable = 'KYC not applicable',
  KYCDeadlinePassed = 'KYC deadline passed',
  ClaimYourTokens = 'Claim your tokens'
}
