'use client';

import CheckIcon from '@app/assets/images/icon/check.svg';
import { useAddress, useUser } from '@thirdweb-dev/react';
import { useState } from 'react';
import {
  Modal,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  useToast
} from '@chakra-ui/react';
import { useProjectDetails } from '@app/api/useProjectDetails';
import { Button } from '@app/components/Button';
import { queryClient } from '@app/context/AppContextWrapper';

import { ProjectId } from '@app/types';
import { CustomConnectWalletButton } from '@app/components/CustomConnectWalletButton';

type Props = {
  onClose: () => void;
  projectId: ProjectId;
  emailAddress: string | undefined;
  isUserAlreadyRegistered: boolean | undefined;
};

const validateEmail = (email: string) => {
  var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

  return re.test(email);
};

export const EmailModal = ({
  onClose,
  projectId,
  emailAddress,
  isUserAlreadyRegistered
}: Props) => {
  const { name } = useProjectDetails(projectId);
  const { user, isLoggedIn, isLoading } = useUser();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const address = useAddress();

  const submitInterest = async ({
    publicKey,
    emailAddress
  }: {
    publicKey: string;
    emailAddress: string | undefined;
  }) => {
    const response = await fetch('/api/whitelist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ emailAddress, publicKey, projectId })
    });

    queryClient.invalidateQueries({ queryKey: ['userRegisterations'] });

    if (response.ok) {
      onClose();
      toast({
        title: 'Successfully Registered interest.',
        status: 'success'
      });
    } else {
      toast({
        title: 'Something went wrong',
        description: `There was an error signing up for the whitelist. Please try again later.`,
        status: 'error'
      });
    }
  };

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{name} Whitelist</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoggedIn ? (
            emailAddress && validateEmail(emailAddress) ? (
              !isUserAlreadyRegistered ? (
                <Flex direction="column" w="100%" align="start" py={4}>
                  <Text fontSize="medium">
                    The {name} public sale is coming soon. We already have your
                    email on file. Press the button below to confirm your
                    interest.
                  </Text>
                  <Flex w="100%" justify="center" mt={10}>
                    <Button
                      disabled={loading}
                      onClick={async () => {
                        setLoading(true);
                        setError(false);

                        await submitInterest({
                          publicKey: address!,
                          emailAddress: undefined
                        });
                        setLoading(false);
                      }}
                      text="Express Interest"
                    />
                  </Flex>
                </Flex>
              ) : (
                <>
                  <Text fontSize="medium">
                    We already have your interest on file for {name}! Sit tight
                    and wait to see if you get selected.
                  </Text>
                  <Flex w="100%" justify="center" my={5}>
                    <Box w="44px" h="44px">
                      <CheckIcon />
                    </Box>
                  </Flex>
                </>
              )
            ) : (
              <Flex direction="column" w="100%" align="start" py={4}>
                <Text fontSize="medium">
                  The {name} public sale is coming soon. Leave your email here
                  to be informed about the next steps.
                </Text>
                <FormControl isRequired mt={10} isInvalid={error}>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onFocus={() => setError(false)}
                  />
                  {error && (
                    <FormErrorMessage>
                      Please enter a valid email.
                    </FormErrorMessage>
                  )}
                  <FormHelperText>{`We'll never share your email.`}</FormHelperText>
                </FormControl>
                <Flex w="100%" justify="center" mt={10}>
                  <Button
                    disabled={loading}
                    onClick={async () => {
                      setLoading(true);
                      setError(false);

                      const valid = validateEmail(email);

                      if (!valid) {
                        setError(true);
                        setLoading(false);

                        return;
                      }

                      await submitInterest({
                        publicKey: address!,
                        emailAddress: email
                      });
                      setLoading(false);
                    }}
                    text="Express Interest"
                  />
                </Flex>
              </Flex>
            )
          ) : (
            <Flex direction="column" w="100%" align="center" gap={4} py={4}>
              <Text fontSize="lg">
                Please connect your wallet to express interest.
              </Text>
              <CustomConnectWalletButton />
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
