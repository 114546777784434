'use client';

import { Flex } from '@chakra-ui/react';
import { useUser, useUserRegisterations } from '@app/api/profile';
import { useState } from 'react';
import Hero from './components/hero';
import { UpcomingProjects } from './components/UpcomingProjects';
import { MultichainInformation } from './components/MultichainInformation';
import { Footer } from '@app/components/Footer';
import { EmailModal } from './components/UpcomingProjects/EmailModal';
import { useAddress } from '@thirdweb-dev/react';
import { type ProjectId } from '@app/types';
import { type ProjectRegistration } from '@app/pages/api/userRegisterations';
import { useUserDetailsContext } from '@app/context/UserContext';

const LandingPage = () => {
  const [selectedProjectId, setSelectedProjectId] = useState<ProjectId | null>(
    null
  );

  const userDetails = useUserDetailsContext();

  return (
    <Flex flexDir="column" w="100%">
      <Hero />
      <UpcomingProjects
        setSelectedProjectId={(id: ProjectId) => setSelectedProjectId(id)}
      />
      <MultichainInformation />
      <Footer />
      {selectedProjectId && (
        <EmailModal
          onClose={() => {
            setSelectedProjectId(null);
          }}
          projectId={selectedProjectId}
          emailAddress={userDetails?.user?.emailAddress}
          isUserAlreadyRegistered={userDetails?.userRegisterations?.some(
            (registration: ProjectRegistration) =>
              registration?.projectId === selectedProjectId
          )}
        />
      )}
    </Flex>
  );
};

export default LandingPage;
