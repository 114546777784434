import { Flex, Box, Text } from '@chakra-ui/react';
import { type ReactElement } from 'react';

type Props = {
  title: string;
  description: string;
  icon: ReactElement;
};

export const InformationCard = ({ title, description, icon }: Props) => (
  <Flex
    bg="white"
    borderRadius={24}
    color="black"
    textAlign="center"
    h="100%"
    p={{ base: 8, md: 16 }}
  >
    <Flex direction="column" align="center">
      {icon}
      <Text mt={4} fontSize={'3xl'} fontWeight="bold" fontStyle="italic">
        {title}
      </Text>
      <Text
        mt={6}
        opacity={0.5}
        fontSize="md"
        fontStyle="normal"
        fontWeight="semibold"
      >
        {description}
      </Text>
    </Flex>
  </Flex>
);
