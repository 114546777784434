import { FaArrowDown } from 'react-icons/fa6';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import _ from 'lodash';

import NavigationBar from '@app/components/NavigationBar';
import { Button } from '@app/components/Button';

import Red1 from '@app/assets/images/bg/red1.svg';
import Red2 from '@app/assets/images/bg/red2.svg';

const Hero = () => {
  const scrollToHash = function (element_id: string) {
    const element = document.getElementById(element_id);

    element?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const toast = useToast();
  const comingSoon = _.throttle(
    () =>
      toast({
        title: 'Coming Soon',
        status: 'info'
      }),
    5000
  );

  return (
    <Box w="100%" h="calc(70vh)" minH={{ base: '400px', md: '660px' }}>
      <Flex
        w="100%"
        direction="column"
        alignItems="center"
        h="100%"
        display="relative"
        zIndex={1}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          filter="blur(151.9949951171875px)"
          zIndex={0}
        >
          <Box position="absolute" top={0} left={0}>
            <Red1 />
          </Box>
          <Box position="absolute" right={0}>
            <Red2 />
          </Box>
        </Box>
        <NavigationBar />
        <Flex
          flex={1}
          direction="column"
          justify="center"
          align="center"
          h="calc(80vh)"
          minH={{ base: '500px', md: '800px' }}
          zIndex={1}
          position="absolute"
          left={0}
          right={0}
        >
          <Flex direction="column" alignItems="center" w="100%" px={4}>
            <Flex
              fontSize={{ base: '5xl', md: '7xl' }}
              direction="column"
              alignItems="center"
              fontStyle="italic"
              fontWeight="extrabold"
              lineHeight="none"
              textAlign="center"
              wordBreak="break-word"
            >
              <Text color="brandRed">GET EARLY ACCESS</Text>
              <Text>TO THE BEST SUPERCHAIN</Text>
              <Text>LAUNCHES</Text>
            </Flex>
            <Text
              textAlign="center"
              fontSize={['sm', 'xl']}
              opacity={0.5}
              fontWeight="bold"
              mt={5}
              maxW={'650px'}
            >
              S1 is a cross-chain community funding platform for the top
              projects launching tokens on Optimism, Base, Mode and Zora
            </Text>
            <Flex gap={3} mt={12}>
              <Button
                text="Upcoming Sales"
                onClick={() => scrollToHash('upcomingProjects')}
                icon={<FaArrowDown />}
              />
              <Button
                text="Apply for Launch"
                onClick={comingSoon}
                variant="OUTLINE"
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Hero;
