'use client';

import { Flex, Text, Box } from '@chakra-ui/react';
import { FaShuffle } from 'react-icons/fa6';
import { maxWidths } from '@app/theme';
import { ProjectCard } from './ProjectCard';
import { InformationCard } from './InformationCard';
import CommunityIcon from './assets/community.svg';
import TransparentIcon from './assets/transparent.svg';
import CuratedIcon from './assets/curated.svg';
import Red3 from '@app/assets/images/bg/red3.svg';

import { type ProjectId } from '@app/types';
import { useProjectsContext } from '@app/context/ProjectsContext';

export const projectIds: ProjectId[] = ['IRONCLAD', 'KIM', 'IONIC'];

type Props = {
  setSelectedProjectId: (id: ProjectId) => void;
};

export const UpcomingProjects = ({ setSelectedProjectId }: Props) => {
  const { projects } = useProjectsContext();

  return (
    <Flex
      w="100%"
      bg="#050607"
      py={[16, 32]}
      textColor="white"
      id="upcomingProjects"
      position="relative"
      justify="center"
    >
      <Box
        position="absolute"
        w="100%"
        left={0}
        right={0}
        top={{ base: '30%', md: 0 }}
        opacity={0.5}
        zIndex={0}
      >
        <Red3 />
      </Box>
      <Flex
        flexDirection="column"
        width="100%"
        align="center"
        px={4}
        maxWidth="1024px"
      >
        <Flex flexDir="column" align="start" gap={5} w="100%" maxW={maxWidths}>
          <FaShuffle size={32} />
          <Text fontSize={'4xl'} fontWeight="bold">
            Upcoming Projects
          </Text>
        </Flex>
        <Flex flexDir="column" align="center" mt={{ base: 4, md: 10 }} w="100%">
          <Flex
            width="100%"
            flexDir="column"
            align="center"
            gap={6}
            mt={10}
            position="relative"
            zIndex={1}
          >
            {projectIds.map(projectId => {
              let project = projects ? projects[projectId] : null;
              const openEmailSignup = () => setSelectedProjectId(projectId);

              return (
                <Box w="100%" maxW={maxWidths} key={projectId}>
                  <ProjectCard
                    projectId={projectId}
                    openEmailSignup={openEmailSignup}
                    project={project}
                    enabled
                  />
                </Box>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          mt={{ base: 24, md: 64 }}
          fontSize={{
            base: '3xl',
            md: '7xl'
          }}
          fontStyle="italic"
          fontWeight="extrabold"
          direction="column"
          lineHeight="none"
          align="center"
          w="100%"
          maxW={maxWidths}
          zIndex={1}
        >
          <Text color="brandRed">CONNECTING PROJECTS</Text>
          <Text color="white">WITH USERS</Text>
          <Flex
            align="center"
            w="100%"
            justify="between"
            gap={10}
            mt={24}
            direction={{
              base: 'column',
              md: 'row'
            }}
            wordBreak="break-word"
          >
            <InformationCard
              title="COMMUNITY FIRST"
              description="S1 empowers community members to support projects from day 1"
              icon={<CommunityIcon />}
            />
            <InformationCard
              title="TRANSPARENT LAUNCHES"
              description="All the launches are on-chain with data being available on explorer"
              icon={<TransparentIcon />}
            />
            <InformationCard
              title="CURATED PROJECTS"
              description="S1 works with the community to curate the highest quality launches"
              icon={<CuratedIcon />}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
